import React from 'react';
import { Link } from 'react-router-dom';
import './StorySnippet.css';

interface StorySnippetProps {
  id: string;
  title: string;
  snippet: string;
}

function StorySnippet({ id, title, snippet }: StorySnippetProps) {
  return (
    <div className="story-snippet">
      <h3>{title}</h3>
      <p>{snippet}</p>
      <Link to={`/story/${id}`}>Read More</Link>
    </div>
  );
}

export default StorySnippet;