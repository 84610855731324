import { StoryData } from "../types/story";

  
  export const fetchStories = (): StoryData[] => {
    const stories: StoryData[] = [
      {
        id: '1',
        title: 'אוסמוזה הפוכה',
        snippet: 'התנסות בכתיבה בדמות שקשה להזדהות איתה',
        link: 'https://docs.google.com/document/d/1eifyb3n_JKChYt46_QAuGaDhxFfDQIqj/edit?usp=sharing&ouid=104009330847795240976&rtpof=true&sd=true',
      },
      {
        id: '2',
        title: 'דר ג׳ייקל',
        snippet: 'סיפור בזמן הווה שמשלב זכרונות המשפיעים על ההווה',
        link: 'https://docs.google.com/document/d/1hEHSBz0zR8EQwlaDwFUHe1rBFj2qaGGD/edit?usp=sharing&ouid=104009330847795240976&rtpof=true&sd=true',
      },
      {
        id: '3',
        title: 'סקארט',
        snippet: 'סיפור הישרדות עם גוף שני דומיננטי',
        link: 'https://docs.google.com/document/d/1AFaEYjV5Eg59w-E0Wyfkpq7AO3DhApzS/edit?usp=sharing&ouid=104009330847795240976&rtpof=true&sd=true',
      },
      {
        id: '4',
        title: 'צלופן',
        snippet: 'כתיבה חושית ושימוש בלשון פיגורטיבי מוקצן',
        link: 'https://docs.google.com/document/d/1uz3hTvpDwkcaS5IVVja4dacVj1lP01CU/edit?usp=drive_link'
      },
      {
        id: '5',
        title: 'ממלכת טורגובץ',
        snippet: 'מפגש בין שני דמויות מעולמות שונוים - ניסיון בהתאמת שפה לדמות',
        link: 'https://docs.google.com/document/d/1WprKKvadpvpov2ej0yTER7yiXyE1rYHs/edit?usp=drive_link&ouid=104009330847795240976&rtpof=true&sd=true' },
        {
          id: '6',
          title: 'ים סוף',
          snippet: 'סיפור על פרידה - כתיבה בשני פספקטיבות של דמויות שונות',
          link: 'https://docs.google.com/document/d/1GxQVLj_OLvEo1_wrWIYen6cLuKIb-6_I/edit?usp=sharing&ouid=104009330847795240976&rtpof=true&sd=true'
        }
    ];
  
    return stories;
  };