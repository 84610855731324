import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Story.css';

interface StoryProps {
  title: string;
  link: string;
}

function Story({ title, link }: StoryProps) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <article className="container">
      <button onClick={handleBackClick} className='button'>
        <i className="fas fa-arrow-right"></i>
      </button>
      <h2 className="title">{title}</h2>
      <iframe src={`${link}}&embedded=true`} width="100%" height="600px"></iframe>
    </article>
  );
}

export default Story;