  import React, { useEffect, useState } from 'react';
  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
  import Story from './components/Story/Story';
  import StorySnippet from './components/StorySnippet/StorySnippet';
  import './App.css';
  import { fetchStories } from './services/fetchStories';
  import { StoryData } from './types/story';
  import YoutubeCarousel from './components/YoutubeCarousel/YoutubeCarousel';

  function App() {
    const [stories, setStories] = useState<StoryData[]>([]);
    const youtubeVideos = [
      { id: 'ejQHIqElU8Y?si=ihxUZQrVo8FcLIZz', title: 'Video 1' },
      { id: 'EXepvUOiHQ4?si=z3KxmyG1j_6eOdDa', title: 'Video 2' },
      { id: 'd11hH2AQBp8?si=8RxFmTxfxTNlOFhs', title: 'Video 3' },
      { id: 'WAl8Rqangqo?si=9sfylb8AzuRB6Uha', title: 'Video 4' },
      { id: 'zl6LTh0SP5s?si=wqFg5LrfpWy-5AIj', title: 'Video 5' },
      { id: 'vUp_jq7pok8?si=XGBB-RB0taDgUUnc', title: 'Video 6'}
    ];
    useEffect(() => {
      const getStories = async () => {
        try {
          const fetchedStories = await fetchStories();
          setStories(fetchedStories);
        } catch (error) {
          console.error('Error fetching stories:', error);
        }
      };

      getStories();
    }, []);

    return (
      <Router>
        <div className="App">
          <header>
            <h1>אור יבש</h1>
          </header>
          <main>
            <Routes>
              <Route path="/" element={
                <>
                  <section>
                    <h2>סיפורים יבשים</h2>
                    <div className="story-grid">
                      {stories.map(story => (
                        <StorySnippet key={story.id} id={story.id} title={story.title} snippet={story.snippet} />
                      ))}
                    </div>
                  </section>
                  <section>
                    <h2>סרטונים מיובשים</h2>
                    <YoutubeCarousel videos={youtubeVideos} />
                  </section>
                  <section>
                    <h2>המלצות מתביישות</h2>
                    {/* Add your recommendations here */}
                  </section>
                </>
              } />
              {stories.map(story => (
                <Route key={story.id} path={`/story/${story.id}`} element={<Story title={story.title} link={story.link} />} />
              ))}
            </Routes>
          </main>
          <footer>
            <p>&copy; 2024 אור יבש. All rights reserved.</p>
          </footer>
        </div>
      </Router>
    );
  }

  export default App;