import React, { useState } from 'react';
import './YoutubeCarousel.css';

interface YoutubeVideo {
  id: string;
  title: string;
}

interface YoutubeCarouselProps {
  videos: YoutubeVideo[];
}

function YoutubeCarousel({ videos }: YoutubeCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviousVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? videos.length - 1 : prevIndex - 1));
  };

  const goToNextVideo = () => {
    setCurrentIndex((prevIndex) => (prevIndex === videos.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="Youtube-carousel">
      <div className="video-container">
        <div className="video-wrapper">
          <iframe
            width="560"
            height="315"
            src={`https://www.Youtube.com/embed/${videos[currentIndex].id}`}
            title={videos[currentIndex].title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          </div>
        <button
          className="carousel-button previous"
          onClick={goToPreviousVideo}
          aria-label="Previous Video"
        >
          &lt;
        </button>
        <button
          className="carousel-button next"
          onClick={goToNextVideo}
          aria-label="Next Video"
        >
          &gt;
        </button>
        <div className="video-number" aria-label={`Video ${currentIndex + 1} of ${videos.length}`}>
          {currentIndex + 1}/{videos.length}
        </div>
      </div>
    </div>
  );
}

export default YoutubeCarousel;